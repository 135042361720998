import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Header from "./components/Header";
import ScrollUp from "./components/ScrollUp";
import Footer from "./components/Footer";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Portfolio from "./components/pages/Portfolio";
import Contact from "./components/pages/Contact";
import NotFound from "./components/pages/NotFound";
import Loader from "./components/Loader";
import "./assets/css/app.css";

function App() {
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState(localStorage.getItem("mode"));

  useEffect(() => {
    // %%%%%%%%%%%%%%%%%%%%[ Hide Loading Screen ]%%%%%%%%%%%%%%%%%%%%
    setLoading(false);
    document.body.style.overflow = "auto";

    // %%%%%%%%%%%%%%%%%%%%[ Disable Context Menu ]%%%%%%%%%%%%%%%%%%%%
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });

    document.onkeydown = function (e) {
      if (e.keyCode === 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === "C".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
        return false;
      }
    };
  }, []);

  const Content = () => {
    return (
      <React.Fragment>
        <ReactTooltip className="tooltipTheme" id="tooltipControl" />
        {loading ? (
          <Loader />
        ) : (
          <Router>
            <Header setTheme={setTheme} theme={theme} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<NotFound theme={theme} />} />
            </Routes>
            <ScrollUp />
            <Footer />
          </Router>
        )}
      </React.Fragment>
    );
  };

  return Content();
}

export default App;
