import { useEffect } from "react";
import { Typewriter } from "react-simple-typewriter";
import ReactTooltip from "react-tooltip";
import Icons from "../icons";
import Images from "../images";
import "../../assets/css/home.css";
// const enCVName = "AhmedFARHAT-CV-En.pdf";
// const frCVName = "AhmedFARHAT-CV-Fr.pdf";
const { myPicture, enCV_url, frCV_url } = require("../links.json");

const Home = () => {
  useEffect(() => {
    // %%%%%%%%%%%%%%%%%%%%[ Hide Menu List on Route Change ]%%%%%%%%%%%%%%%%%%%%
    document.getElementById("menu-list").style.maxHeight = "0px";

    // %%%%%%%%%%%%%%%%%%%%[ Button Click Effect ]%%%%%%%%%%%%%%%%%%%%
    let buttons = document.querySelectorAll(".cv-btn");
    Array.prototype.forEach.call(buttons, function (b) {
      b.addEventListener("click", createRipple);
    });

    function createRipple(event) {
      let ripple = document.createElement("span");
      ripple.classList.add("ripple");
      let max = Math.max(this.offsetWidth, this.offsetHeight);
      ripple.style.width = ripple.style.height = max * 2 + "px";
      let rect = this.getBoundingClientRect();
      ripple.style.left = event.clientX - rect.left - max + "px";
      ripple.style.top = event.clientY - rect.top - max + "px";
      this.appendChild(ripple);
    }

    // %%%%%%%%%%%%%%%%%%%%[ CV Multiple Languages ]%%%%%%%%%%%%%%%%%%%%
    let langPanel = document.querySelector(".selected-lang");
    let currentLangText = document.querySelector(".selected-lang span");
    let currentLangIcon = document.querySelector(".selected-lang img");
    let langList = document.querySelector(".lang-menu ul");
    let enCvBtn = document.getElementById("en");
    let frCvBtn = document.getElementById("fr");
    let downloadBtn = document.getElementById("cvBtn");

    langPanel.addEventListener("click", () => {
      langList.style.display = "block";

      window.addEventListener("click", function (e) {
        if (!langPanel.contains(e.target)) {
          langList.style.display = "none";
        }
      });
    });

    enCvBtn.addEventListener("click", () => {
      currentLangIcon.src = Images.ukFlag;
      currentLangIcon.alt = "En";
      currentLangText.innerText = "English";
      downloadBtn.href = enCV_url;
      // downloadBtn.download = enCVName;
      langList.style.display = "none";
      ReactTooltip.hide();
    });

    frCvBtn.addEventListener("click", () => {
      currentLangIcon.src = Images.frFlag;
      currentLangIcon.alt = "Fr";
      currentLangText.innerText = "French";
      downloadBtn.href = frCV_url;
      // downloadBtn.download = frCVName;
      langList.style.display = "none";
      ReactTooltip.hide();
    });
  }, []);

  useEffect(() => {
    // %%%%%%%%%%%%%%%%%%%%[ Tooltip ]%%%%%%%%%%%%%%%%%%%%
    ReactTooltip.rebuild();
  });

  return (
    <section className="home">
      <div className="showcase-content">
        <div className="presentation">
          <h3 className="shining-text">Hi there !</h3>
          <h1>
            I am <mark className="myName">Ahmed Farhat</mark>
          </h1>
          <h3 className="workplace">Software Engineer</h3>
          <p>
            As a creative problem-solver, I'm driven by the challenge of turning
            ideas into reality and delivering solutions that truly meet my
            clients' needs. Whether you have a specific project in mind or want
            to bounce around some new creative ideas, I'm always open and ready
            to discuss. Your satisfaction is my top priority, and I pride myself
            on being respectful, motivated, and rigorous in everything I do.
            Let's work together to bring your visions to life!
          </p>
          <div className="typewriter-container">
            <p>
              I love{" "}
              <Typewriter
                cursor
                delaySpeed={700}
                typeSpeed={100}
                loop="false"
                words={["Coding", "Designing"]}
              />
            </p>
          </div>
          <div className="unselectable">
            <a
              href={enCV_url}
              id="cvBtn"
              // download={enCVName}
            >
              <button className="cv-btn">
                Download CV <span className="reactIcon">{Icons.download}</span>
              </button>
            </a>
            <br className="sub-line" />{" "}
            <div
              className="lang-menu"
              data-tip="Choose my CV's language !"
              data-place="right"
              data-effect="solid"
              data-for="tooltipControl"
              data-event-off="click"
            >
              <div className="selected-lang">
                <img src={Images.ukFlag} alt="En" className="flag-icon" />
                <span>English</span>
              </div>
              <ul>
                <li>
                  <span id="en">
                    <img src={Images.ukFlag} alt="En" className="flag-icon" />
                    <span>English</span>
                  </span>
                </li>
                <li>
                  <span id="fr">
                    <img src={Images.frFlag} alt="Fr" className="flag-icon" />
                    <span>French</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="photo-container unselectable">
          <img src={myPicture} alt="Ahmed Farhat" className="picture" />
        </div>
      </div>
    </section>
  );
};

export default Home;
