import React, { useEffect, useState } from "react";
import Icons from "../icons";
import Images from "../images";
import "../../assets/css/about.css";

const About = () => {
  const [myAge, setMyAge] = useState(0);
  const [filterValue, setFilterValue] = useState("show-all");

  useEffect(() => {
    // %%%%%%%%%%%%%%%%%%%%[ Hide Menu List on Route Change ]%%%%%%%%%%%%%%%%%%%%
    document.getElementById("menu-list").style.maxHeight = "0px";

    // %%%%%%%%%%%%%%%%%%%%[ Set my Age ]%%%%%%%%%%%%%%%%%%%%
    let birthday = new Date("1999/04/20");
    let today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    let monthDiff = today.getMonth() + 1 - (birthday.getMonth() + 1);
    let dayDiff = today.getDate() - birthday.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    setMyAge(age);
  }, []);

  // %%%%%%%%%%%%%%%%%%%%[ Filter Skills ]%%%%%%%%%%%%%%%%%%%%
  useEffect(() => {
    let allSkill = document.querySelectorAll(".posts .post");
    let filteredSkill = document.querySelectorAll(`.posts .${filterValue}`);
    let allFilterAreaItems = document.querySelectorAll(".menu ul li > span");
    let selectedFilterAreaItem = document.querySelector(
      `.menu ul li > span[data-filter="${filterValue}"]`
    );

    allFilterAreaItems.forEach((e) => {
      e.classList.remove("active");
    });
    selectedFilterAreaItem.classList.add("active");

    if (filterValue === "show-all") {
      allSkill.forEach((e) => {
        e.style.display = "block";
      });
    } else {
      allSkill.forEach((e) => {
        e.style.display = "none";
      });
      filteredSkill.forEach((e) => {
        e.style.display = "block";
      });
    }
  }, [filterValue]);

  return (
    <section className="about">
      <div className="showcase-content">
        <div className="infos">
          <div className="personal">
            <div className="container">
              <div className="title">
                <h2>Personal Informations</h2>
              </div>
              <div className="to-ask first">
                <ul className="multiple">
                  <li>
                    <span className="question">first name :</span>{" "}
                    <span className="response">Ahmed</span>
                  </li>
                  <li>
                    <span className="question">last name :</span>{" "}
                    <span className="response">Farhat</span>
                  </li>
                </ul>
              </div>
              <div className="to-ask second">
                <ul className="multiple">
                  <li>
                    <span className="question">age :</span>{" "}
                    <span className="response" id="myAge">
                      {myAge}
                    </span>
                  </li>
                  <li>
                    <span className="question">nationality :</span>{" "}
                    <span className="response">Tunisian</span>
                  </li>
                </ul>
              </div>
              <div className="to-ask third">
                <ul className="multiple">
                  <li>
                    <span className="question">languages :</span>
                    <div className="languages">
                      <div className="bar arabic">
                        <div className="lang">
                          <span>Arabic</span>
                          <span className="level">90%</span>
                        </div>
                        <div className="progress-line">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar french">
                        <div className="lang">
                          <span>French</span>
                          <span className="level">70%</span>
                        </div>
                        <div className="progress-line">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar english">
                        <div className="lang">
                          <span>English</span>
                          <span className="level">60%</span>
                        </div>
                        <div className="progress-line">
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span className="question">freelance :</span>{" "}
                    <span className="response dispo">Available</span>
                    {/* <div className="freelance-links unselectable">
                      <a
                        className="freelance-btn"
                        href="https://www.upwork.com/freelancers/~01a4d24e2a60909bed"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="reactIcon">{Icons.user}</span>Upwork
                      </a>{" "}
                      <a
                        className="freelance-btn"
                        href="https://www.fiverr.com/ahmedfarhat99"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="reactIcon">{Icons.user}</span>
                        Fiverr
                      </a>
                    </div> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="results">
            <div className="container">
              <div className="indicator">
                <div className="box-stats">
                  <h3>01</h3>
                  <p>
                    Years of<span>Experience</span>
                  </p>
                </div>
              </div>
              <div className="indicator">
                <div className="box-stats">
                  <h3>10</h3>
                  <p>
                    Completed<span>Projects</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="separator" />
        <div className="skills">
          <div className="title">
            <h2>Skills</h2>
          </div>
          <div className="menu">
            <ul>
              <li>
                <span
                  data-filter="show-all"
                  onClick={() => setFilterValue("show-all")}
                >
                  All
                  <span className="reactIcon">
                    {filterValue === "show-all"
                      ? Icons.arrowDown
                      : Icons.arrowUp}
                  </span>
                </span>
              </li>
              <li>
                <span
                  data-filter="show-progLang"
                  onClick={() => setFilterValue("show-progLang")}
                >
                  Programming Languages
                  <span className="reactIcon">
                    {filterValue === "show-progLang"
                      ? Icons.arrowDown
                      : Icons.arrowUp}
                  </span>
                </span>
              </li>
              <li>
                <span
                  data-filter="show-frame"
                  onClick={() => setFilterValue("show-frame")}
                >
                  Frameworks / Libraries
                  <span className="reactIcon">
                    {filterValue === "show-frame"
                      ? Icons.arrowDown
                      : Icons.arrowUp}
                  </span>
                </span>
              </li>
              <li>
                <span
                  data-filter="show-db"
                  onClick={() => setFilterValue("show-db")}
                >
                  Databases
                  <span className="reactIcon">
                    {filterValue === "show-db"
                      ? Icons.arrowDown
                      : Icons.arrowUp}
                  </span>
                </span>
              </li>
              <li>
                <span
                  data-filter="show-designing"
                  onClick={() => setFilterValue("show-designing")}
                >
                  Design
                  <span className="reactIcon">
                    {filterValue === "show-designing"
                      ? Icons.arrowDown
                      : Icons.arrowUp}
                  </span>
                </span>
              </li>
              <li>
                <span
                  data-filter="show-soft"
                  onClick={() => setFilterValue("show-soft")}
                >
                  Softwares
                  <span className="reactIcon">
                    {filterValue === "show-soft"
                      ? Icons.arrowDown
                      : Icons.arrowUp}
                  </span>
                </span>
              </li>
              <li>
                <span
                  data-filter="show-extra"
                  onClick={() => setFilterValue("show-extra")}
                >
                  Others
                  <span className="reactIcon">
                    {filterValue === "show-extra"
                      ? Icons.arrowDown
                      : Icons.arrowUp}
                  </span>
                </span>
              </li>
            </ul>
          </div>
          <div className="container posts">
            <div className="box post show-extra">
              <div className="skill">
                <div className="logo">
                  <img src={Images.html} alt="HTML" />
                </div>
                <h4>HTML</h4>
              </div>
            </div>
            <div className="box post show-extra">
              <div className="skill">
                <div className="logo">
                  <img src={Images.css} alt="CSS" />
                </div>
                <h4>CSS</h4>
              </div>
            </div>
            <div className="box post show-progLang">
              <div className="skill">
                <div className="logo">
                  <img src={Images.javascript} alt="JavaScript" />
                </div>
                <h4>JavaScript</h4>
              </div>
            </div>
            <div className="box post show-progLang">
              <div className="skill">
                <div className="logo">
                  <img src={Images.typescript} alt="TypeScript" />
                </div>
                <h4>TypeScript</h4>
              </div>
            </div>
            <div className="box post show-extra">
              <div className="skill">
                <div className="logo">
                  <img src={Images.nodejs} alt="Node.js" />
                </div>
                <h4>Node.js</h4>
              </div>
            </div>
            <div className="box post show-frame">
              <div className="skill">
                <div className="logo">
                  <img src={Images.reactNative} alt="React Native" />
                </div>
                <h4>React Native</h4>
              </div>
            </div>
            <div className="box post show-frame">
              <div className="skill">
                <div className="logo">
                  <img src={Images.expo} alt="Expo" />
                </div>
                <h4>Expo</h4>
              </div>
            </div>
            <div className="box post show-frame">
              <div className="skill">
                <div className="logo">
                  <img src={Images.bootstrap} alt="Bootstrap" />
                </div>
                <h4>Bootstrap</h4>
              </div>
            </div>
            <div className="box post show-frame">
              <div className="skill">
                <div className="logo">
                  <img src={Images.tailwindcss} alt="Tailwind CSS" />
                </div>
                <h4>Tailwind CSS</h4>
              </div>
            </div>
            <div className="box post show-frame">
              <div className="skill">
                <div className="logo">
                  <img src={Images.express} alt="Express.js" />
                </div>
                <h4>Express.js</h4>
              </div>
            </div>
            <div className="box post show-frame">
              <div className="skill">
                <div className="logo">
                  <img src={Images.electron} alt="Electron.js" />
                </div>
                <h4>Electron.js</h4>
              </div>
            </div>
            <div className="box post show-frame">
              <div className="skill">
                <div className="logo">
                  <img src={Images.reactjs} alt="React.js" />
                </div>
                <h4>React.js</h4>
              </div>
            </div>
            <div className="box post show-frame">
              <div className="skill">
                <div className="logo">
                  <img src={Images.redux} alt="Redux" />
                </div>
                <h4>Redux</h4>
              </div>
            </div>
            <div className="box post show-frame">
              <div className="skill">
                <div className="logo">
                  <img src={Images.axios} alt="Axios" />
                </div>
                <h4>Axios</h4>
              </div>
            </div>
            <div className="box post show-frame">
              <div className="skill">
                <div className="logo">
                  <img src={Images.socketio} alt="Socket.io" />
                </div>
                <h4>Socket.io</h4>
              </div>
            </div>
            <div className="box post show-progLang">
              <div className="skill">
                <div className="logo">
                  <img src={Images.java} alt="Java" />
                </div>
                <h4>Java</h4>
              </div>
            </div>
            <div className="box post show-progLang">
              <div className="skill">
                <div className="logo">
                  <img src={Images.php} alt="PHP" />
                </div>
                <h4>PHP</h4>
              </div>
            </div>
            <div className="box post show-progLang">
              <div className="skill">
                <div className="logo">
                  <img src={Images.cs} alt="C#" />
                </div>
                <h4>C#</h4>
              </div>
            </div>
            <div className="box post show-db">
              <div className="skill">
                <div className="logo">
                  <img src={Images.mongodb} alt="MongoDB" />
                </div>
                <h4>MongoDB</h4>
              </div>
            </div>
            <div className="box post show-db">
              <div className="skill">
                <div className="logo">
                  <img src={Images.firebase} alt="Firebase" />
                </div>
                <h4>Firebase</h4>
              </div>
            </div>
            <div className="box post show-db">
              <div className="skill">
                <div className="logo">
                  <img src={Images.mysql} alt="MySQL" />
                </div>
                <h4>MySQL</h4>
              </div>
            </div>
            <div className="box post show-db">
              <div className="skill">
                <div className="logo">
                  <img src={Images.sqlite} alt="SQLite" />
                </div>
                <h4>SQLite</h4>
              </div>
            </div>
            <div className="box post show-extra">
              <div className="skill">
                <div className="logo">
                  <img src={Images.git} alt="Git" />
                </div>
                <h4>Git</h4>
              </div>
            </div>
            <div className="box post show-soft show-extra">
              <div className="skill">
                <div className="logo">
                  <img src={Images.postman} alt="Postman" />
                </div>
                <h4>Postman</h4>
              </div>
            </div>
            <div className="box post show-soft show-extra">
              <div className="skill">
                <div className="logo">
                  <img src={Images.androidStudio} alt="Android Studio" />
                </div>
                <h4>Android Studio</h4>
              </div>
            </div>
            <div className="box post show-designing show-soft">
              <div className="skill">
                <div className="logo">
                  <img src={Images.figma} alt="Figma" />
                </div>
                <h4>Figma</h4>
              </div>
            </div>
            <div className="box post show-designing show-soft">
              <div className="skill">
                <div className="logo">
                  <img src={Images.illustrator} alt="Adobe Illustrator" />
                </div>
                <h4>Adobe Illustrator</h4>
              </div>
            </div>
            <div className="box post show-designing show-soft">
              <div className="skill">
                <div className="logo">
                  <img src={Images.xd} alt="Adobe Xd" />
                </div>
                <h4>Adobe Xd</h4>
              </div>
            </div>
          </div>
        </div>
        <hr className="separator" />
        <div className="resume">
          <div className="title">
            <h2>
              Education <span>&</span> Experience
            </h2>
          </div>
          <div className="title sub-1">
            <h2>Education</h2>
          </div>
          <div className="education">
            <div className="container">
              <ul>
                <li>
                  <div className="line edu">
                    <span className="reactIcon">{Icons.graduation}</span>
                  </div>
                  <span className="release-date">2021 - 2024</span>
                  <h3 className="degree">
                    Master's degree in Software Engineering
                  </h3>
                  <p className="place">
                    International Multidisciplinary School of Sousse (EPI
                    Digital School)
                  </p>
                </li>
                <li>
                  <div className="line edu">
                    <span className="reactIcon">{Icons.graduation}</span>
                  </div>
                  <span className="release-date">2018 - 2021</span>
                  <h3 className="degree">
                    Bachelor's degree in Applied Network and Services
                    Administration
                  </h3>
                  <p className="place">
                    Higher Institute of Computer Sciences of Mahdia (ISIMa)
                  </p>
                </li>
                <li>
                  <div className="line edu">
                    <span className="reactIcon">{Icons.graduation}</span>
                  </div>
                  <span className="release-date">2018</span>
                  <h3 className="degree">
                    High School Diploma in Technical Sciences
                  </h3>
                  <p className="place">
                    Ahmed Noureddine High School of Sousse (Boys' High School)
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <hr className="sub-separator" />
          <div className="title sub-2">
            <h2>Experience</h2>
          </div>
          <div className="experience">
            <div className="container">
              <ul>
                <li>
                  <div className="line exp">
                    <span className="reactIcon">{Icons.briefcase}</span>
                  </div>
                  <span className="release-date">April, August 2024</span>
                  <span className="duration">
                    4 months (Hybrid) ·{" "}
                    <span className="type">
                      Master's end-of-studies internship
                    </span>
                  </span>
                  <h3 className="post">
                    Full Stack JS Developer (MERN Stack)
                    <span className="place">Webify Technology</span>
                  </h3>
                  <img src={Images.webify} alt="Webify Technology" />
                </li>
                <li>
                  <div className="line exp">
                    <span className="reactIcon">{Icons.briefcase}</span>
                  </div>
                  <span className="release-date">July, August 2023</span>
                  <span className="duration">
                    2 months (Hybrid) ·{" "}
                    <span className="type">Summer internship</span>
                  </span>
                  <h3 className="post">
                    Full Stack JS Developer (MERN Stack)
                    <span className="place">Whitecape Technologies</span>
                  </h3>
                  <img src={Images.whitecape} alt="Whitecape Technologies" />
                </li>
                <li>
                  <div className="line exp">
                    <span className="reactIcon">{Icons.briefcase}</span>
                  </div>
                  <span className="release-date">February - July 2021</span>
                  <span className="duration">
                    5 months (Remote) ·{" "}
                    <span className="type">
                      Bachelor's end-of-studies internship
                    </span>
                  </span>
                  <h3 className="post">
                    Full Stack JS Developer
                    <span className="place">Discovery Intech</span>
                  </h3>
                  <img src={Images.discovery} alt="Discovery Intech" />
                </li>
                <li>
                  <div className="line exp">
                    <span className="reactIcon">{Icons.briefcase}</span>
                  </div>
                  <span className="release-date">August 2020</span>
                  <span className="duration">
                    1 month (On-site) ·{" "}
                    <span className="type">Summer internship</span>
                  </span>
                  <h3 className="post">
                    After-sales Service Technician
                    <span className="place">Mediavision</span>
                  </h3>
                  <img src={Images.mediavision} alt="Mediavision" />
                </li>
                <li>
                  <div className="line exp">
                    <span className="reactIcon">{Icons.briefcase}</span>
                  </div>
                  <span className="release-date">2019 - 2020</span>
                  <span className="duration">
                    <span className="type">Community life</span>
                  </span>
                  <h3 className="post">
                    President<span className="place">EventUS ISIMa Club</span>
                  </h3>
                  <p className="description">
                    Social club created on the initiative of a group of
                    students.
                  </p>
                  <img src={Images.eventus} alt="EventUS ISIMa Club" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="separator" />
        <div className="hobbies">
          <div className="title">
            <h2>Hobbies</h2>
          </div>
          <div className="container">
            <div className="box">
              <img src={Images.camping} alt="Camping" className="image" />
              <div className="overlay">
                <div className="text">Camping</div>
              </div>
            </div>
            <div className="box">
              <img src={Images.fishing} alt="Fishing" className="image" />
              <div className="overlay">
                <div className="text">Fishing</div>
              </div>
            </div>
            <div className="box">
              <img src={Images.traveling} alt="Traveling" className="image" />
              <div className="overlay">
                <div className="text">Traveling</div>
              </div>
            </div>
            <div className="box">
              <img src={Images.football} alt="Football" className="image" />
              <div className="overlay">
                <div className="text">Football</div>
              </div>
            </div>
            <div className="box">
              <img src={Images.gaming} alt="Gaming" className="image" />
              <div className="overlay">
                <div className="text">Gaming</div>
              </div>
            </div>
            <div className="box">
              <img src={Images.swimming} alt="Swimming" className="image" />
              <div className="overlay">
                <div className="text">Swimming</div>
              </div>
            </div>
            <div className="box">
              <img src={Images.fitness} alt="Fitness" className="image" />
              <div className="overlay">
                <div className="text">Fitness</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
